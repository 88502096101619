import React, { useEffect, useState } from "react";

export default function DetaFormSubmit({ formurl, title }) {
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    // Fetch public IP address
    fetch("https://api64.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error("IP fetch error:", error));
  }, []);

  const domain = window.location.hostname; // Get current domain
  const iframeSrc = `${formurl}/${domain}/${ipAddress}`;

  return (
    <>
      <div className="form_id" id="form_id">
        <iframe
          width="100%"
          height="520"
          src={iframeSrc}
          frameBorder="0"
          allowFullScreen
          title={title}
        ></iframe>
      </div>
    </>
  );
}
